import React from "react";

export const TelegramIcon = ({ width = "28", height = "28", fill = "#A2BBFF" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M13.4055 16.6509L15.9271 19.5177C16.8604 20.5794 17.3279 21.1106 17.8171 20.9807C18.3055 20.8516 18.4735 20.1532 18.8087 18.7555L20.6676 11.0027C21.1848 8.84977 21.4431 7.77411 20.8691 7.24289C20.2951 6.71167 19.3003 7.10678 17.3107 7.89622L8.08166 11.5619C6.49033 12.1942 5.69467 12.51 5.64411 13.0529C5.63816 13.1082 5.63816 13.164 5.64411 13.2193C5.69311 13.763 6.48722 14.0811 8.077 14.7181C8.79644 15.0066 9.15655 15.1513 9.41477 15.4274C9.44381 15.4585 9.47181 15.4907 9.49877 15.5239C9.73677 15.8194 9.83788 16.2083 10.0409 16.983L10.4212 18.4351C10.618 19.1895 10.7168 19.5675 10.9758 19.6189C11.2348 19.6702 11.4595 19.3575 11.9099 18.7314L13.4055 16.6509ZM13.4055 16.6509L13.159 16.3942C12.8774 16.1002 12.7366 15.954 12.7366 15.772C12.7366 15.59 12.8766 15.443 13.159 15.1498L15.938 12.2533" 
        stroke={fill} 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};
