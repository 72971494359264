
import { MarkerIcon } from "../../../assets/icons"
import { NotMarkerIcon } from "../../../assets/icons/NotMarkerIcon"
import { tarifFeaturesType } from "../../../redux/types/@types"
import styles from "./ItemPossibiliti.module.scss"

type ItemPossibilityType = {
  feature:tarifFeaturesType
}

const ItemPossibility = ({
  feature
}:ItemPossibilityType) => {
  const {subTitle, tariffs} = feature;
  

  return(
    <div className={styles.container}>
      <h4>
        {subTitle}
      </h4>
      <div className={styles.signWrap}>
        <div className={styles.sign}>
          {tariffs.free
          ? <MarkerIcon /> 
          : <NotMarkerIcon/>}
        </div>
        <div className={styles.sign}>
          {tariffs.start
          ? <MarkerIcon/> 
          : <NotMarkerIcon/>}
        </div>
        <div className={styles.sign}>
          {tariffs.pro
          ? <MarkerIcon /> 
          : <NotMarkerIcon/>}  
        </div>
        <div className={styles.sign}>
          {tariffs.pro_plus
          ? <MarkerIcon/> 
          : <NotMarkerIcon/>}  
        </div>
      </div>
    </div>

  )
}

export default ItemPossibility;