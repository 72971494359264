
import { ButtonType } from "../../utils/@globalTypes";
import { tariffs, tarifPossible } from "../../utils/tarifDatas";
import Button from "../Button";
import ItemPossibility from "./ItemPossibility";
import styles from "./PricePossibilities.module.scss"

const PricePossibilities = () => {

  const header = tariffs.map(item=> {
    return(
      <div className={styles.column_head} key={item.code}>
        <div className={styles.column_title}>
          {item.label}
        </div>
        <div className={styles.btn_head}>
          <Button
            title="Выбрать"
            type={ButtonType.PRIMARY_SMALL}
          />
        </div>
      </div>
    )
  })

  const features = tarifPossible.map(item=> {
    const featItem = item.features.map(itemFeat=>{
      return (
        <div key={itemFeat.subTitle}>
          <ItemPossibility
            feature={itemFeat}
          />         
        </div>
      )
    })
    return(
      <div className={styles.featContainer} key={item.title}>
        <h3>
          {item.title}
        </h3>
        <div className={styles.featWrap} >
          {featItem}
        </div>
      </div>
    )
  })

  return(
    <section className={styles.container}>
     <div className={styles.header}>
      <div className={styles.aside}>
        Возможности
      </div>
      {header}      
     </div>
     {features}
    </section>
  )
}

export default PricePossibilities;