import React from 'react';
import classNames from 'classnames';

import styles from "./PriceCard.module.scss";
import { tarifCardType } from '../../redux/types/@types';
import { MarkerIcon } from '../../assets/icons';
import Button from '../Button';
import { ButtonType } from '../../utils/@globalTypes';

type priceCardProps = {
  tarif:tarifCardType,
  isCurrent?:boolean 
  period: 12 | 6 | 1 
}

const PriceCard = ({
  tarif,
  isCurrent  
}:priceCardProps) => {

  const {
    isPaying, 
    label, 
    monthlyPrice=0, 
    yearPrice,
    halfYearPrice,
    features
  } = tarif;

  const hedearLabel = isCurrent ? "текущий тариф" 
  : isPaying ? "выгодное предложение" : ""
  
  const currency = "BYN"
  const yearPay = monthlyPrice*12

  const feat = features.map(item=>{
    return(
      <div className={styles.line} key={item}>
        <div className={styles.icon}>
          <MarkerIcon/>
        </div>        
        <div>{item}</div>
      </div>
    )
  })
  return (
    <div className={classNames(styles.container, {
      [styles.paying]:isPaying,
      [styles.current]:isCurrent      
    })}>
      <div className={styles.label}>
        {hedearLabel}
      </div>

      <h2 className={styles.header}>
        {label}
      </h2>
      <div className={styles.month}>
        {monthlyPrice + " " +currency + " / месяц"}
      </div>
      {yearPrice&& 
      <>
        <div className={styles.yearPay}>
          {yearPay + " " + currency}
        </div>
        <div className={styles.yearPrice}>
          <span className={styles.first}>или</span>
          <span className={styles.second}>{" " + yearPrice + " " +currency + " / "}</span>
          <span className={styles.third}>год</span>
        </div>
      </>}
      <div className={styles.featBlock}>
        {feat}
      </div>
     
      <div className={styles.btnContainer}>
        <Button
          title={"Подключить"}
          type={ButtonType.PRIMARY_SMALL}
          wrapperClassName='btnWrap'
        />
      </div>  
    </div>
  )
}

export default PriceCard;