import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageData } from "../../redux/reducers/pageSlice";
import PriceCard from "../../components/PriceCard";
import { tariffs } from "../../utils/tarifDatas";
import styles from "./Prices.module.scss";
import PricePossibilities from "../../components/PricePossibilities";
import { Arrow2Icon } from "../../assets/icons";
import classNames from "classnames";
import RadioButton from "../../components/RadioButton";

const Prices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Информация о тарифах",
        pageDescription: "Настройки тарифного плана",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);
  const current = "PRO_PLUS" 
  const RadioButtonList = ["Ежегодно", "Раз в полгода", "Ежемесячно"];
  const [periodChoice, setPeriodChoice] = useState(RadioButtonList[0])
  const [isOpen, setIsOpen] = useState(false)

  const cards = tariffs.map((item,index)=>{
    return(
      <div key={index}>
        <PriceCard 
          tarif = {item}
          isCurrent={item.code===current? true: false}  
          period = {periodChoice===RadioButtonList[0]? 12:periodChoice===RadioButtonList[0]?6:1 }        
        />
      </div>
    )
  })

  return (
    <>
      <h2>Мы рады предложить Вам следующие тарифные планы:</h2>
      <div className={styles.container}>
        Скоро здесь будут тарифы
        {/* <section className={styles.blockWrap}> 
          <RadioButton
            id="tarif"
            name="tarif"
            btnsList={RadioButtonList}
            onClick={setPeriodChoice}
            activeTab={periodChoice}
            size="price"
          />
          <div className={styles.cardsWrapper}>
            {cards}
          </div>
        </section>  */}
        <div className={styles.abilityWrap}>
          <h3 className={styles.toggleTitle} onClick={()=>setIsOpen(!isOpen)}>
            {isOpen&&"Скрыть весь список возможностей"}
            {!isOpen&&"Показать весь список возможностей"}
            <span className={classNames(styles.arrow, isOpen && styles.arrowOpen )}>
              <Arrow2Icon fill="#0F2664"/>
            </span>
          </h3>
          {isOpen&&<PricePossibilities/>}
        </div>
      </div>
    </>
  );
};

export default Prices;