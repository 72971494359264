import React, {FC } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import {ru} from "date-fns/locale/ru";
import {subDays} from 'date-fns';
import classNames from "classnames";
import { CalendarIcon, DeleteIcon } from "../../assets/icons";
import 'react-datepicker/dist/react-datepicker.min.css'

import styles from "./Calendar.module.scss";

type CalendarProps = {
  selectedDate:Date|undefined;
  onChange:(value: any) => void;
  onBlur?:(value: any) => void;
  minDate?:Date;    //ограничивает промотку календаря
  maxDate?:Date;    //ограничивает промотку календаря
  placeholderText?:string;
  disabled?:boolean;
  isError?:boolean;
  //пропсы для выбора диапазона дат
  selectsRange?:boolean;
  startDate?:Date;  // state для начала диапазона дат
  endDate?:Date;    // state для конца диапазона дат

  // чтобы диапазон дат выводился в двух Inputs - необходимо два экземпляра DatePicker,
  // в одном из них указать "selectsStart", "startDate", "endDate",
  // а во втором "selectsEnd", "startDate", "endDate"
  selectsStart?:boolean;
  selectsEnd?:boolean;

  excludeDates?:Date[];   //массив дат, не доступных для выбора
  excludeDateIntervals?:  //массив интервалов дат, не доступных для выбора
    { start:Date, 
      end: Date 
    }[]
  
  highlightDates?:Date[];  //массив дат, которые отдельно подсветятся в календаре

  monthsShown?:number;  // "2" отображение двух месяцев одновременно
  showPreviousMonths?:boolean;  //отображение предыдущего месяца при выводе двух сразу
  showYearDropdown?:boolean;
  showMonthDropdown?:boolean;

  disabledAllBeforeToday?:boolean; //деактивируем все даты до текущей
  isSmall?:boolean;  //меньший размер для формы брони
  inputClassname?:string;  //  дополнительные классы для стилизации
  wrapperInputClassname?:string;  //  дополнительные классы для стилизации
  clearCalendar?:(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>void;  //принудительная очистка выбранного периода в режиме Range
  calendarClassName?:string,
  name?:string,
  id?:string,
  errText?:any;
}

const Calendar: FC<CalendarProps> = ({
  selectedDate,
  onChange,
  onBlur,
  minDate,
  maxDate,
  placeholderText,
  disabled,
  selectsRange,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  excludeDates,
  excludeDateIntervals=[],
  highlightDates,
  monthsShown,
  showYearDropdown,
  showMonthDropdown,
  showPreviousMonths,
  disabledAllBeforeToday,
  isSmall,
  isError,
  inputClassname,
  clearCalendar,
  calendarClassName="myCalendar",
  wrapperInputClassname,
  name,
  id,
  errText
}) => {

  registerLocale('ru', ru);
  
  disabledAllBeforeToday&&excludeDateIntervals.push({         //деактивируем все даты до текущей
      start: new Date(0),
      end: subDays(new Date(), 1)
    })
    const onBlurCalendar = () => {     
      onBlur && onBlur(true);
    };
    const isClear = selectsRange&&!!startDate
  return(
    <div className={classNames(styles.calWrap, wrapperInputClassname)}>
    <DatePicker
        name={name}
        id={id}
        className={styles.container}
        toggleCalendarOnIconClick
        selected={selectedDate}
        onChange={onChange}
        dateFormat={'dd.MM.YYYY'}
        calendarClassName={styles[calendarClassName]}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        popperClassName="myPopper"
        showPopperArrow={false}  //убираем отображение стрелки над календарем
        popperPlacement="bottom"
        locale='ru' 
        // icon={<CalendarIcon/>}       
        customInput={          
            <input 
              readOnly
              className={classNames(
                styles.dateInput, 
                isSmall&&styles.small, 
                disabled&&styles.dis, 
                isError&&styles.error,
                inputClassname,
                inputClassname&&styles[inputClassname],
                !startDate&&styles.empty
               
              )}
             
              // type="date"
            />
          
        }
        placeholderText={placeholderText}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        excludeDates={excludeDates}
        excludeDateIntervals={excludeDateIntervals}
        highlightDates={highlightDates}
        monthsShown={monthsShown}
        showPreviousMonths={showPreviousMonths}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown
        // readOnly
        showMonthDropdown={showMonthDropdown}
        dropdownMode="select"
        scrollableMonthYearDropdown
        onKeyDown={(e) => {
          e.preventDefault();
      }}
        dayClassName={(date) =>
          (date.getDay() === 6 || date.getDay() === 0) ? "customWeekEnd" :"customWeekDay"
        } 
        onBlur={onBlurCalendar}        
        autoComplete="none"
      />
        {isClear&&
          <div className={styles.customIcon} onClick={clearCalendar}>
            <DeleteIcon/>
          </div>}
        {errText&&<span className={styles.errorText}>{errText}</span>}  
      </div>
  )
}

export default Calendar;