import React from "react";

export const NotMarkerIcon = ({ width = "24", height = "24", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <path d="M20.4852 12L3.51465 12" stroke={fill} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};