import React, { FC } from "react";
import classNames from "classnames";
import styles from "./RadioButton.module.scss";

type RadioButtonProps = {
  onClick: (key: string) => void;
  btnsList: string[];
  // btnsList: RadioButtonTypes;
  activeTab?: string;
  size?: "small" | "big" | "booking" | "price";
  isDisabled?: boolean;
  name?:string;
  id?:string
};

const RadioButton: FC<RadioButtonProps> = ({ onClick, btnsList, activeTab, size, isDisabled, name, id }) => {

  const onTabClick = (key: string) => () => onClick(key);
  if(isDisabled){
    return(
      <div className={styles.disabled}>
        {activeTab?activeTab:"-"}
      </div>
    )
  }
  return (
    <div className={classNames(styles.tabsWrapper, size==="booking"&& styles.wrapBooking)}>
      {btnsList.map((item) => {
        return (
          <button
            name={name}
            id={id}
            type="button"
            key={item}
            className={classNames(styles.tab, {
              [styles.activeTab]: activeTab === item,
              [styles.notActiveTab]: activeTab && activeTab !== item,
              [styles.big]: size === "big",
              [styles.booking]: size === "booking",
              [styles.price]: size === "price"
            })}
            onClick={onTabClick(item)}            
          >
            {item }
          </button>
        );
      })}
    </div>
  );
};

export default RadioButton;
