import { InstagramIcon, TelegramIcon, TelephoneIcon } from "../../assets/icons"
import styles from "./ContactsBlock.module.scss"

const ContactsBlock = () => {

  return(
    <section className={styles.container}>
      <div className={styles.column}>
        <a className={styles.linkItem} href="https://t.me/+TcNe1oCREvBjMGEy" target="blank">
          <div className={styles.icon}>
            <TelegramIcon/>
          </div>
          <span>Техподдержка</span>
        </a>
        <a className={styles.linkItem} href="https://t.me/+TcNe1oCREvBjMGEy" target="blank">
          <div className={styles.icon}>
            <TelegramIcon/>
          </div>
          <span>Сообщество Витаем</span>
        </a>
      </div>
      <div className={styles.column}>
        <a className={styles.linkItem} href="tel:+375297516178">
          <div className={styles.icon}>
            <TelephoneIcon width="22px" height="22px" fill="#A2BBFF"/>
          </div>
          <span>+375(29)751-6178</span>
        </a>
        <a className={styles.linkItem} href="https://www.instagram.com/vitaem_org/?hl=ru" target="blank">
          <div className={styles.icon}>
            <InstagramIcon/>
          </div>
          <span>Vitaem</span>
        </a>
      </div>
    </section>
  )
}

export default ContactsBlock