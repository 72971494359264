import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./Sidebar.module.scss";
import {
  CategoryIcon,
  CloseIcon,
  HomeIcon,
  LogoIcon,
  OwnerIcon,
  SettingsIcon,
  ShortLogo,
  SlidersIcon,
  TarifIcon,
} from "../../../assets/icons";
import { Link, NavLink } from "react-router-dom";
import { RoutesList } from "../../../App";
import { SidebarButtonsList } from "./@types";
import { useDispatch, useSelector } from "react-redux";
import { AuthSelectors, logoutUser } from "../../../redux/reducers/authSlice";
import { getUserType, useClickOutside } from "../../../utils/functions";
import ContactsBlock from "../../../components/ContactsBlock";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const Sidebar = () => {
  const userData = useSelector(AuthSelectors.getUserInfo);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(false);
  const [textVisibility, setTextVisibility] = useState(false);
  const [ismobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const [isownerMenuOpen, setisOwnerMenuOpen] = useState(false);
  const [isLandscape, setIslandscape] = useState(false);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if(width>height && width>450 ){
      setIslandscape(true)
    }else{
      setIslandscape(false)
    }

  },[width, height])

  const mouseEnterHandler = () => {
    setTrigger(true);
  };

  const mouseLeaveHandler = () => {
    setTrigger(false);
  };

  useEffect(() => {
    if (trigger) {
      const timeoutId = setTimeout(() => {
        setTextVisibility(true);
      }, 100);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setTextVisibility(false);
    }
  }, [trigger]);

  const BUTTON_LIST: SidebarButtonsList = [
    {
      title: "Шахматка",
      content: <HomeIcon />,
      path: RoutesList.checkerboard.fullPath,
      fill: true,
    },
    {
      title: "Бронирование",
      content: <CategoryIcon fill="#8692A6" />,
      path: RoutesList.bookingList.fullPath,
      fill: false,
    },
    // {
    //   title: "Гости",
    //   content: <UsersIcon />,
    //   path: "2",
    //   fill: false,
    // },
    {
      title: "Управление объектами",
      content: <SlidersIcon />,
      path: RoutesList.objectManagement.fullPath,
      fill: false,
    },
    // {
    //   title: "Дополнительные услуги",
    //   content: <BagIcon />,
    //   path: "3",
    //   fill: true,
    // },
    {
      title: "Настройки личного кабинета",
      content: <SettingsIcon />,
      path: RoutesList.settings.fullPath,
      fill: true,
    },
    {
      title: "Информация о тарифах",
      content: <TarifIcon />,
      path: RoutesList.prices.fullPath,
      fill: true,
    },
  ];

  const menu = BUTTON_LIST.map(({ content, path, fill, title }) => {
    return (
      <li key={path}>
        <NavLink
          to={path}
          className={({ isActive }) =>
            classNames(styles.link, {
              [styles.activeLink]: isActive,
            })
          }
        >
          <div
            className={classNames({
              [styles.btnWrapper]: fill,
              [styles.btnWrapperStroke]: !fill,
              [styles.activeLinkIcon]: fill,
              [styles.activeLinkIconStroke]: !fill,
            })}
          >
            {content}
          </div>
          <p
            className={classNames(styles.titleLink, styles.avtiveLinkText, {
              [styles.show]: textVisibility,
            })}
          >
            {title}
          </p>
        </NavLink>
      </li>
    );
  });
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);
  const ownerMenuRef = useRef(null);
  const ownerBtnRef = useRef(null);

  useClickOutside(menuRef, menuBtnRef, () => setisMobileMenuOpen(false));
  useClickOutside(ownerMenuRef, ownerBtnRef, () => setisOwnerMenuOpen(false));

  useEffect(() => {
    console.log(isownerMenuOpen);
  }, [isownerMenuOpen]);

  useEffect(() => {
    if (ismobileMenuOpen || isownerMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [ismobileMenuOpen, isownerMenuOpen]);

  return (
    <div>
      <div className={styles.container}>
        <div
          className={styles.menu}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <div className={styles.logoWrapper}>
            <div
              className={classNames(styles.shortLogo, {
                [styles.show]: !textVisibility,
              })}
            >
              <ShortLogo />
            </div>
            <div
              className={classNames(styles.logo, {
                [styles.show]: textVisibility,
              })}
            >
              <LogoIcon width="211px" height="41px" />
            </div>
          </div>
          <nav className={styles.navigation}>
            <ul className={styles.navlist}>{menu}</ul>
          </nav>
          <footer className={styles.footer}>

            {/* <ContactsBlock/> */}
          </footer>
        </div>
      </div>
      <div className={styles.mobileWrapper}>
        {(isownerMenuOpen || ismobileMenuOpen) && (
          <div
            className={styles.shadowFilter}
            onClick={() => {
              setisOwnerMenuOpen(false);
              setisMobileMenuOpen(false);
            }}
          ></div>
        )}
        <input
          className={styles.checkbox}
          type="checkbox"
          name=""
          id=""
          checked={ismobileMenuOpen}
          onChange={() => setisMobileMenuOpen(!ismobileMenuOpen)}
          ref={menuBtnRef}
        />
        <div className={styles.mobileContainer}>
          <button
            type="button"
            ref={ownerBtnRef}
            className={styles.mobileIcon}
            onClick={() => setisOwnerMenuOpen(!isownerMenuOpen)}
          >
            <OwnerIcon />
          </button>
          <ShortLogo width="36" height="36" />
          <div className={styles.burger}>
            <div className={styles.hamburgerLines}>
              <span className={styles.line1}></span>
              <span className={styles.line2}></span>
              <span className={styles.line3}></span>
            </div>
          </div>
        </div>
        <div className={classNames(styles.mobMenu, 
          {[styles.landscape]:isLandscape}
        )} ref={menuRef}
        style={{
          width:isLandscape?392:"100%"          
        }}>
          <nav className={styles.mobNavigation} >
            <ul className={styles.navlist} onClick={() => setisMobileMenuOpen(false)}>
              {menu}
            </ul>
          </nav>
          <ContactsBlock/>
        </div>
      </div>
      {isownerMenuOpen && (
        <div ref={ownerMenuRef} className={styles.ownerWrap}>
          <div className={styles.ownerHead}>
            <div className={styles.ownerTitle}>
              {!userData ? "Загрузка..." : `${userData.first_name} ${userData.last_name}`}
            </div>
            <div onClick={() => setisOwnerMenuOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.ownerStatus}>
            {!userData ? "" : getUserType(userData.user.status)}
          </div>
          <Link to={RoutesList.settings.fullPath} className={styles.ownerAction}>
            Профиль
          </Link>
          <div className={styles.ownerAction} onClick={() => dispatch(logoutUser())}>
            Выйти
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
